
import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

const AffiliateUser = () => {
    const [affiliateUsers, setAffiliateUsers] = useState([]);
    const [allData, setallData] = useState([]);
    const [amountData, setAmountData] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState([]);

    const navigate = useNavigate()

    const handleAmountInput = (e, id) => {
        const newAmount = e.target.value;
        setAmountData(prevData => {
            const index = prevData.findIndex(user => user.userId === id);
            if (index === -1) return [...prevData, { userId: id, amount: +newAmount }];
            else return prevData.map((user, idx) => idx === index ? { ...user, amount: +newAmount } : user);
        });
    };

    const selectUser = (e, id) => {
        if (e.target.checked) {
            setSelectedUserId([...selectedUserId, id]);
        }
        else {
            let filteredData = selectedUserId?.filter((item) => item !== id)
            setSelectedUserId(filteredData)
        }
    };

    const linkFollow = (row) => {
        return (
            <div>
                <button className="btn btn-dark btn-sm   me-2" onClick={() => navigate(`/dashboard/AffiliateUserDetails/${row?._id}`)} > Referred User</button>
                {/* {row?.status === 'Active' ?
                    <button className="btn btn-success btn-sm  me-2" onClick={() => { handleStatus(row?.id, 'Inactive') }} >Active</button>
                    : <button className="btn btn-danger btn-sm  me-2" onClick={() => { handleStatus(row?.id, 'Active') }}  >Inactive</button>} */}
            </div>
        );
    };

    const BonusAction = (row) => {
        return (
            <div>
                <button className="btn btn-success btn-sm my-2 me-2" onClick={() => handleUpdateBonusAmount('Single', row?._id, "Credit")} > Deposit</button>
                <button className="btn btn-danger btn-sm my-2 me-2" onClick={() => handleUpdateBonusAmount('Single', row?._id, "Debit")} > Withdraw</button>
            </div>
        );
    };

    const statusAction = (row) => {
        return (
            <div>
                <button className="btn btn-danger btn-sm my-2 me-2" onClick={() => { handleStatus(row?._id, false) }} > Deactivate</button>
            </div>
        );
    };

    const amountInput = (row) => {
        let inputData = amountData?.filter((item) => row?._id === item.userId)[0]
        return (
            <input className="form-control form-control-solid" id="inputLastName" type="number" placeholder="Amount" onWheel={(e) => e.target.blur()} onChange={(e) => handleAmountInput(e, row?._id)} value={inputData ? inputData?.amount : ""} />
        );
    };

    const selectBox = (row) => {
        return (
            <div>
                <input className="form-check-input" type="checkbox" onChange={(e) => selectUser(e, row?._id)} />
            </div>
        );
    };

    const columns = [
        { name: <>Select</>, wrap: true, shrink: true, width: "80px", sort: true, selector: selectBox, },
        { name: <>Sr no.</>, shrink: true, width: "80px", selector: (row, index) => index + 1 },
        { name: <>Registration Date</>, width: "150px", wrap: true, sort: true, selector: row => moment(row?.createdAt).format("Do MMMM YYYY") },
        { name: <>User ID</>, width: "150px", selector: row => row._id, wrap: true },
        { name: <> Name</>, width: "150px", sort: true, wrap: true, selector: row => row?.firstName + row?.lastName || "----", },
        { name: <>Email</>, width: "150px", sort: true, wrap: true, selector: row => row?.emailId, },
        { name: <>Total Investment(By Referrals)</>, width: "150px", sort: true, wrap: true, selector: row => row?.total_referred_balance, },
        { name: <>Comission</>, width: "180px", sort: true, grow: 2, wrap: true, selector: amountInput, },
        { name: <>Action</>, width: "150px", wrap: true, selector: BonusAction, },
        { name: <>View</>, width: "150px", wrap: true, selector: linkFollow, },
        { name: <> Affiliate Status</>, width: "150px", wrap: true, selector: statusAction, },
    ];

    useEffect(() => {
        handleaffiliateUsers();
    }, []);

    const handleStatus = async (_id, status) => {
        try {
            LoaderHelper.loaderStatus(true)
            const result = await AuthService.updateAffiliateStatus(_id, status)
            if (result?.success) {
                handleaffiliateUsers();
                alertSuccessMessage(result.message);
            } else {
                alertErrorMessage(result.message);
            }
        } catch (error) {
            alertErrorMessage(error);
        } finally {
            LoaderHelper.loaderStatus(false)
        }
    };

    const handleUpdateBonusAmount = async (type, id, updateType) => {
        LoaderHelper.loaderStatus(true);
        let usersBonusAmountList;
        if (type === 'Single') {
            usersBonusAmountList = amountData.filter(item => id === item.userId);
            if (usersBonusAmountList?.length === 0 || usersBonusAmountList[0]?.amount === 0) {
                alertErrorMessage("Please enter amount");
                return
            }
        }
        else {
            usersBonusAmountList = amountData.filter(item => selectedUserId.includes(item.userId));
            if (selectedUserId?.length === 0) {
                alertErrorMessage(`Select user to ${updateType} comission`)
                return
            } else if (usersBonusAmountList?.length === 0) {
                alertErrorMessage("Enter amount to selected user")
                return
            }
        };
        let finalList = usersBonusAmountList?.map((item) => ({ amount: item?.amount, user_id: item?.userId, type: updateType }))
        try {
            const result = await AuthService.updateUserInrBalance(finalList);
            if (result?.success) { alertSuccessMessage(result?.message); }
            else alertErrorMessage(result?.message)

        } catch (error) { alertErrorMessage(error?.message) }
        finally { LoaderHelper.loaderStatus(false); }

    }

    const handleaffiliateUsers = async () => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.affiliateList()
            if (result.success) {
                setAffiliateUsers(result?.data.reverse());
                setallData(result?.data);
            }
        }
        catch (error) { alertErrorMessage(error); }
        finally { LoaderHelper.loaderStatus(false); }
    };

    function searchObjects(e) {
        const keysToSearch = ["firstName", "lastName", "emailId", "mobileNumber", "_id"];
        const userInput = e.target.value;
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = allData.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setAffiliateUsers(matchingObjects);
    };
    
    const csvData = allData?.map((item) => ({ RegData: item?.createdAt, Email: item?.emailId || "---", Mobile: item?.mobileNumber || "---", TotalInvestment: item?.total_referred_balance, Name: item?.firstName + item?.lastName || "---", UserId: item?._id }))

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className=" mt-4 d-flex align-items-center justify-content-between ">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon">
                                            <i className="fa fa-wave-square"></i>
                                        </div>
                                        Affiliate User List
                                    </h1>
                                    <div >
                                        <button className="btn btn-success btn-md " onClick={() => handleUpdateBonusAmount('Multiple', "", "Credit")}>
                                            Deposit
                                        </button>
                                        <button className="btn btn-danger btn-md mx-1" onClick={() => handleUpdateBonusAmount('Multiple', "", "Debit")}>
                                            Withdraw
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div className="card-header d-flex justify-content-between">
                            Affiliate Details
                            <div className="col-5">
                                <input className="form-control form-control-solid" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
                            </div>
                            <div className="dropdown">
                                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-download me-3"></i> Export
                                </button>
                                <div
                                    className="dropdown-menu animated--fade-in-up"
                                    aria-labelledby="dropdownFadeInUp"
                                >
                                    <CSVLink data={csvData} className="dropdown-item">
                                        Export as CSV
                                    </CSVLink>
                                </div>
                            </div>

                        </div>
                        <div className="card-body">
                            <div className="table-responsive" width="100%">
                                <DataTable columns={columns} data={affiliateUsers} direction="auto" responsive subHeaderAlign="right" subHeaderWrap striped highlightOnHover fixedHeader />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
};

export default AffiliateUser;
