const appUrl = "https://backend.fipnext.com";
export const deployedUrl = `${window.origin}/`

export const ApiConfig = {
  // =========EndPoints==========
  login: "login",
  newPassword: "forgot-password",
  getdata: "pending-kyc-user",
  cpbalancebycoin: "cpbalancebycoin",
  getverifyData: "approve-kyc-user",
  getrejectedData: "rejected-kyc-user",
  getkycData: "kyc-details",
  verifyIdentity: "update-kyc-status",
  rejectIdentity: "verify-kyc",
  getusers: "getUsers",
  getTotaluser: "user-count",
  getVerified: "approved-kyc-count",
  getPending: "pending-kyc-count",
  getSupport: "support",
  getwithdrawal: "withdrawlrequest",
  getregistration: "counnewreg",
  allkyc: "kyc-details",
  coinlist: "coinList",
  currencyPair: "allsinglecoinlist",
  getSubAdminList: "sub-admin-list",
  AddsubAdmin: "add-new-admin",
  AddTrade: "addnewtrader",
  subadmindelete: "delete_subadmin",
  getallorder: "getOrders",
  addNotify: "add_notification",
  deleteNotify: "delete-notification",
  notificationList: "notification-list",
  helplist: "getallhelplist",
  SubadminStatus: "admin_status",
  updateSubadmin: "edit_subadmin",
  tradeStatus: "kycsetstatus",
  getInrWithrawList: "withdraw_request",
  userreqapprove: "confirmInrWithdraw",
  userreqreject: "rejectInrWithdraw",
  addAdTicket: "addAdTicket",
  ticketList: "ticketlist",
  adminsupport: "adminsupport",
  sendmailtouser: "sendmailtouser",
  documentchange: "documentchange",
  transferhistory: "user-trade-history",
  userWallet: "user-wallet",
  admintransfer: "admintransfer",
  depositrequest: "depositrequest",
  withdrawlrequest: "withdrawlrequest",
  totaldepositrequest: "depositrequest",
  totalwithdrawlrequest: "getWithdrawlReq",
  getmasterbal: "getmasterbal",
  changeseen: "changeseen",
  currencyCoinList: "get-coin",
  walletCoinList: "adminwallet",
  setcoinwithdrawal: "edit-currency",
  getcpaccountinfo: "getcpaccountinfo",
  getcpcoinbalance: "getcpcoinbalance",
  AddCoinPair: "add-pairs",
  AddPairFee: "updatecoinfee",
  getCurrencyPairList: "get-pairs",
  tredingReport: "trading-commission",
  sendFundStatus: "transactionstatus",
  completeWithdrawalRequest: "complete_withdrawal_request",
  PendingWithdrwal: "pending_withdrawal_request",
  CancelledWithdrwal: "cancel_withdrawal_request",
  completeDepositRequest: "complete_deposit_request",
  completePendingRequest: "pending_deposit_request",
  inrdepositreq: "deposit_request",
  confirmInrDeposit: "update_deposit_status",
  rejectInrDeposit: "update_deposit_status",
  getUserWalletList: "select_given_coin",
  userWalletTransfer: "withdrawalcoin",
  setrewardrate: "kyc/setrewardrate",
  updatetdsrate: "updatetdsrate",
  updatemailTamplate: "updatemailTamplate",
  traderreport: "user-list",
  UserReferral: "user_refer_code",
  userReferralList: "user_referral_list",
  pendingtrader: "pendingtrader",
  fiatwithreq: "fiatwithreq",
  verifiedtrader: "verifiedtrader",
  fiatdepreq: "fiatdepreq",
  addNewCoins: "add-coin",
  getMasterWalletList: "adminwallettransactions",
  admincoinaddress: "admincoinaddress",
  getreceive: "getreceive",
  stakingList: "stacking/getAllUserstacking",
  transactionstatus: "update_withdrawal_status",
  Addbanner: "addbanner",
  bannerList: "getbannerlist",
  updateBanner: "setbanner",
  bannerdelete: "deletebanner",
  BannerStatus: "setbannerStatus",
  deleteCoinPair: "delete-pair",
  walletStatus: "withdrawstatus",
  walletTransfer: "/transfertoother",
  addBankAcc: "add_bank_details",
  getAccDetails: "admin_bank_details",
  fundsTransfer: "debit_credit",
  getCoinList: "get-coin",
  CoinCategory: "coin-category-list",
  tradingCommission: "trading-commission",
  tradeHistory: 'trade-history',
  OrderBook: 'orderbook',
  updateStatus: 'update-user-status',
  tradeById: 'find-trades-by-orderid',
  miscellaneousRequest: 'miscellaneous_withdrawal_request',
  MasterAccount: 'master-account',
  botStatus: 'bot-status',
  cancelOrder: 'cancel-order',
  createCategory:"create-coin-category",
  affiliateList:"all_affiliate_users",
  allInvestors:"all_investors",
  updateAffiliateStatus:"update_affiliate_status",
  updateInvestorStatus:"update_investor_status",
  unmapUser:"unmap_user",
  mapUser:"map_user",
  updateUserInrBalance:"update_user_inr_balance",
  getUserBankDetails:"user_bank_details",
  editBankDetails:"edit_bank_details",
  comissionHistory:"admin_commission_transfer_history",
  create_admin_trading:"create_admin_trading",
  allAdminTrading:"all_admin_trading",
  getOtp: "send-otp",
  createUser: "create-user",
  editProfile: "edit-profile",
  submitKyc: "submit-kyc",
  // ============URLs================
  baseHelp: `${appUrl}/help/`,
  baseTran: `${appUrl}/v1/transaction/`,
  baseUrl: `${appUrl}/v1/admin/`,
  baseUser: `${appUrl}/v1/user/`,
  uploadUrl: `${appUrl}/uploads/`,
  basenotify: `${appUrl}/acc/`,
  baseSecure: `${appUrl}/v1/coin/`,
  baseWallet: `${appUrl}/v1/wallet/`,
  baseCoin: `${appUrl}/v1/coin/`,
  baseReport: `${appUrl}/report/`,
  baseExchange: `${appUrl}/v1/exchange/`,
  uploadcurrency: `${appUrl}/`,
  appUrl: `${appUrl}/`,
};
