import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import { ApiConfig, deployedUrl } from "../../../api/apiConfig/ApiConfig";
import TradeList from "../TradeList";
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";

const TraderDetails = (props) => {
    const [userTradeDetails, setuserTradeDetails] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [gender, setGender] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [verifiStatus, setVerifiStatus] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [verificationDate, setVerificationDate] = useState('');
    const [profilePicture, setProfilePicture] = useState();
    const [lastName, setLastName] = useState('');
    const [selfie, setSelfie] = useState()
    const [frontImg, setFrontImg] = useState()
    const [backImg, setBackImg] = useState()
    const [panImg, setPanImg] = useState()
    const [acNumber, setAcNumber] = useState('');
    const [bankName, setBankName] = useState('');
    const [branch, setBranch] = useState('');
    const [holderName, setHolderName] = useState('');
    const [ifsc, setIfsc] = useState('');
    const [showImage, setShowImage] = useState('');
    const [activeScreen, setActiveScreen] = useState('tradeList');
    const [kycType, setkycType] = useState();
    const [documentType, setdocumentType] = useState();
    const [walletdetails, setwalletdetails] = useState([]);
    const [referralList, setReferralList] = useState([]);

    const [kycInputData, setKycInputData] = useState({ first_name: "", last_name: "", country: "India", address: "", state: "", city: "", zip_code: "", emailId: "", kyc_type: "Personal", pancard_number: "", cpancard_number: "", document_type: "Aadhaar", document_number: "" });
    const [kycInputImages, setKycInputImages] = useState({});
   
    useEffect(() => {
        handleUserData();
        handleTransferhistory();
        userWallet();
        getUserBankDetails();
    }, [])

    const handleKycInput = (e) => {
        const { name, value } = e.target;
        setKycInputData((kycInputData) => ({ ...kycInputData, [name]: value }))
    };
    const handleKycInputImages = (e) => {
        const { name, files } = e.target;
        setKycInputImages((kycInputData) => ({ ...kycInputData, [name]: files[0] }))
    };

    const submitKyc = async () => {
        var formData = new FormData();
        formData.append("first_name", kycInputData?.first_name);
        formData.append("middle_name", kycInputData?.middle_name);
        formData.append("last_name", kycInputData?.last_name);
        formData.append("dob", kycInputData?.dob);
        formData.append("country", kycInputData?.country);
        formData.append("address", kycInputData?.address);
        formData.append("state", kycInputData?.state);
        formData.append("city", kycInputData?.city);
        formData.append("zip_code", kycInputData?.zip_code);
        formData.append("pancard_number", kycInputData?.pancard_number);
        formData.append("confirm_pancard_number", kycInputData?.cpancard_number || kycInputData?.pancard_number);
        formData.append("document_type", kycInputData?.document_type);
        formData.append("document_number", kycInputData?.document_number);
        formData.append("emailId", kycInputData?.emailId);
        formData.append("userId", props.userId);
        formData.append("user_selfie", kycInputImages?.user_selfie || null);
        formData.append("pancard_image", kycInputImages?.pancard_image || null);
        formData.append("document_front_image", kycInputImages?.document_front_image || null);
        formData.append("document_back_image", kycInputImages?.document_back_image || null);

        try {
            await AuthService.submitKyc(formData).then(async result => {
                if (result?.success) {
                    alertSuccessMessage("KYC Updated Successfully!!")
                    handleUserData()
                } else {
                    alertErrorMessage(result.message)
                }
            });
        } catch (error) {
            console.log('error', `${error}`);
        }
    };

    const handleUserData = async () => {
        await AuthService.allKycData(props.userId).then(async result => {
            if (result?.success) {
                try {
                    setKycInputData(result?.data)
                    setFirstName(result.data?.first_name);
                    setEmail(result.data?.emailId);
                    setMobileNumber(result.data?.mobileNumber);
                    setGender(result.data?.gender);
                    setAddress(result.data?.address);
                    setCity(result.data?.city);
                    setState(result.data?.state);
                    setZipCode(result.data?.zip_code);
                    setVerifiStatus(result.data?.kycVerified);
                    setCreatedAt(result.data?.createdAt);
                    setVerificationDate(result.data?.updatedAt);
                    setProfilePicture(result.data?.user_selfie);
                    setLastName(result.data?.last_name);
                    setSelfie(result.data?.user_selfie);
                    setFrontImg(result.data?.document_front_image);
                    setBackImg(result.data?.document_back_image);
                    setPanImg(result.data?.pancard_image);
                    setkycType(result.data?.kyc_type);
                    setdocumentType(result.data?.document_type);
                } catch (error) {
                }
            } else {
                alertErrorMessage('No Data Found')
            }
        })
    }

    const PriceFormat = (row) => {
        return row?.fee && parseFloat(row?.fee?.toFixed(5));
    };

    const columns = [
        { name: "Date/Time", selector: row => moment(row?.updatedAt).format("MMM Do YYYY"), },
        { name: "Currency", selector: row => row.currency, },
        { name: "Fee", selector: PriceFormat },
        { name: "Type", selector: row => row.order_type, },
        { name: "Price", selector: row => row.price, },
        { name: "Quantity", selector: row => row.quantity, },
        { name: "Side", selector: row => row.side, },
        { name: "TDS", selector: row => row.tds, },
    ]

    // ******** Wallet History Table ************//
    const walletdetailscolumns = [
        { name: "Assets", selector: row => row.short_name, },
        { name: "Balance", selector: row => row.balance, },
        { name: "Locked Balance", selector: row => row.locked_balance, },
    ]
    // ******** Users Referral Code Table ************//
    const UserReferralcolumns = [

        { name: "User ID", selector: row => row?.id, wrap: true, },
        { name: "First Name", selector: row => row?.firstName + " " + row?.lastName, wrap: true, },
        { name: "Email ID", selector: row => row?.emailId, wrap: true, },
        { name: "Mobile Number", selector: row => row?.mobileNumber, },
        { name: "Status", selector: row => row?.status, },
    ]

    const handleTransferhistory = async () => {
        await AuthService.transferhistory(props.userId).then(async result => {
            if (result?.success) {
                try {
                    setuserTradeDetails(result?.data);
                } catch (error) {
                    console.log('error', `${error}`);
                }
            } else {
                alertErrorMessage(result.message)
            }
        });
    }

    const userWallet = async () => {
        await AuthService.userWallet(props.userId).then(async result => {
            if (result?.success) {
                try {
                    setwalletdetails(result?.data);
                } catch (error) {
                    console.log('error', `${error}`);
                }
            } else {
                alertErrorMessage(result.message)
            }
        });
    };

    const getUserBankDetails = async () => {
        await AuthService.getUserBankDetails(props.userId).then(async result => {
            if (result?.success) {
                try {
                    let data = result?.data[0] || {}
                    setAcNumber(data?.account_number);
                    setBankName(data?.bank_name);
                    setBranch(data?.branch);
                    setHolderName(data?.holder_name);
                    setIfsc(data?.ifsc);
                } catch (error) {
                    console.log('error', `${error}`);
                }
            } else {
                alertErrorMessage(result.message)
            }
        });
    }

    const handleSubadminDetail = (img) => {
        setShowImage(img);
    };

    const [status, setstatus] = useState(props?.traderData?.master_account);
    const [makerFee, setMakerFee] = useState(props?.traderData?.maker_fee);
    const [takerFee, setTakerFee] = useState(props?.traderData?.taker_fee)
    const [referralCode, setReferralCode] = useState('');


    useEffect(() => {
        if (makerFee > 0 || takerFee > 0) {
            setstatus(true)
        } else {
            setMakerFee(0);
            setTakerFee(0);
            setstatus(false)

        }
        UserReferralCode(props.userId);


    }, [makerFee, takerFee]);


    const UserReferralCode = async (userId) => {
        try {
            LoaderHelper?.loaderStatus(true);
            const result = await AuthService.UserReferral(userId);
            LoaderHelper?.loaderStatus(false);
            if (result?.success) {
                LoaderHelper?.loaderStatus(false);
                setReferralCode(result?.data)
                userReferralList(result?.data)
            } else {
                alertErrorMessage(result.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alertErrorMessage('An error occurred while fetching referral information');
        }
    };
    const userReferralList = async (code) => {
        try {
            LoaderHelper?.loaderStatus(true);
            const result = await AuthService.userReferralList(code);
            LoaderHelper?.loaderStatus(false);
            if (result?.success) {
                setReferralList(result?.data)

            } else {
                alertErrorMessage(result.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alertErrorMessage('An error occurred while fetching referral information');
        }
    };

    return (
        activeScreen === 'tradeList' ?
            <>
                <div id="layoutSidenav_content">
                    <main>
                        <form className="form-data" >
                            <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                                <div className="container-xl px-4">
                                    <div className="page-header-content pt-4">
                                        <div className="row align-items-center justify-content-between">
                                            <div className="col-auto mt-4">
                                                <h1 className="page-header-title">
                                                    <Link to="" className="page-header-icon" onClick={() => setActiveScreen('support')}><i className="fa fa-arrow-left" ></i></Link>
                                                    {firstName} {lastName}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="container-xl px-4 mt-n10" >
                                <div className="row" >

                                    <div className="col-xl-4">
                                        <div className="card mb-4 mb-xl-0">
                                            <div className="card-body py-5">
                                                {/* <!-- Profile picture image--> */}
                                                <div className="text-center">
                                                    {!profilePicture ? <img className="img-account-profile rounded-circle mb-4" src="/assets/img/illustrations/profiles/profile-5.png" alt="profilePicture" /> :
                                                        <img className="img-account-profile rounded-circle mb-4" src={ApiConfig?.appUrl + profilePicture} alt="profilePicture" />
                                                    }
                                                    <h3 className="fw-bolder fs-2 mb-0">
                                                        {firstName} {lastName}
                                                    </h3>
                                                    <div className=" text-gray">
                                                        <small>{email}</small>
                                                    </div>
                                                </div>
                                                <div className=" py-5 px-4 ">
                                                    <ul className="nav nav-pills flex-column" id="cardTab" role="tablist">
                                                        <li className="nav-item  ">
                                                            <a className="menu-link d-flex nav-link active" id="personal-pill" href="#personalPill" data-bs-toggle="tab" role="tab" aria-controls="personal" aria-selected="true">
                                                                <span className="menu-bullet"><span className="bullet"></span>
                                                                </span><span className="menu-title">Personal Information </span>
                                                            </a>
                                                        </li>
                                                        <li className="nav-item  ">
                                                            <a className="menu-link d-flex nav-link" id="Verification-pill" href="#VerificationPill" data-bs-toggle="tab" role="tab" aria-controls="Verification" aria-selected="false"> <span className="menu-bullet"><span className="bullet"></span>
                                                            </span><span className="menu-title">  KYC Verification</span></a>
                                                        </li>
                                                        <li className="nav-item ">
                                                            <a className="menu-link d-flex nav-link" id="bankaccount-pill" href="#bankaccountPill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-bullet"><span className="bullet"></span>
                                                            </span><span className="menu-title">  Bank Account Details</span></a>
                                                        </li>
                                                        <li className="nav-item ">
                                                            <a className="menu-link d-flex nav-link" id="Transitions-pill" href="#TransitionsPill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-bullet"><span className="bullet"></span>
                                                            </span><span className="menu-title">  User Trade Details</span></a>
                                                        </li>
                                                        <li className="nav-item ">
                                                            <a className="menu-link d-flex nav-link" id="Transitions-pill" href="#walletdetails" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-bullet"><span className="bullet"></span>
                                                            </span><span className="menu-title">  User Wallet Details</span></a>
                                                        </li>
                                                        <li className="nav-item ">
                                                            <a className="menu-link d-flex nav-link" id="Transitions-pill" href="#masterAdmin" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-bullet"><span className="bullet"></span>
                                                            </span><span className="menu-title">  Master Trader</span></a>
                                                        </li>
                                                        <li className="nav-item ">
                                                            <a className="menu-link d-flex nav-link" id="Transitions-pill" href="#masterAdmin" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false">
                                                                <span className="menu-bullet"><span className="bullet"></span></span>
                                                                <span className="menu-title"> Users Referral</span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-8" >
                                        <div className="tab-content tab-content-card" id="cardPillContent">
                                            {/* personal INformation */}
                                            <div className="tab-pane card show active" id="personalPill" role="tabpanel" aria-labelledby="personal-pill">
                                                {/* <!-- Profile picture help block--> */}
                                                <div className="card-header">Personal Information</div>
                                                <div className="card-body" >
                                                    <div className="profile_data py-4 px-4" >
                                                        <div className="row" > <span className="col-3" >Name: </span><strong className="col text-dark ">{firstName}</strong></div>
                                                        <hr className="my-3" />
                                                        <div className="row" > <span className="col-3" >Email:</span> <strong className=" col">{email}</strong></div>
                                                        <hr className="my-3" />
                                                        <div className="row" > <span className="col-3" >Mobile Number:</span> <strong className="col"> {mobileNumber} </strong></div>
                                                        {/* <hr className="my-3" /> */}
                                                        {/* <div className="row" > <span className="col-3" >Gender:</span> <strong className=" col"> {gender} </strong></div> */}
                                                        <hr className="my-3" />
                                                        <div className="row" > <span className="col-3 " >Address:</span> <strong className="col"> {address} </strong></div>
                                                        <hr className="my-3" />
                                                        <div className="row" > <span className="col-3" >City:</span> <strong className=" col"> {city} </strong></div>
                                                        <hr className="my-3" />
                                                        <div className="row" > <span className="col-3" >State:</span> <strong className=" col"> {state}  </strong></div>
                                                        <hr className="my-3" />
                                                        <div className="row" > <span className="col-3" >Zip Code:</span> <strong className=" col"> {zipCode} </strong></div>
                                                    </div>
                                                </div>
                                                {/* <!-- Profile picture upload button--> */}
                                            </div>
                                            {/* Personal Information */}

                                            {/* KYC Verification */}
                                            <div className="tab-pane card" id="VerificationPill" role="tabpanel" aria-labelledby="Verification-pill">
                                                <div className="card-header">KYC Verification Details

                                                    <button className="btn btn-primary btn-sm " type="button" data-bs-toggle="modal" data-bs-target="#editKyc" >Update Kyc    </button>

                                                </div>

                                                <div className="card-body" >
                                                    <div className="profile_data py-4 px-4" >
                                                        <div className="row" > <span className="col-3" >Registration Date:</span> <strong className=" col">{createdAt && moment(createdAt).format('Do MMMM YYYY')}</strong></div>
                                                        <hr className="my-3" />
                                                        <div className="row" > <span className="col-3" >Verification Date:</span> <strong className=" col">{verificationDate && moment(verificationDate).format('Do MMMM YYYY')}  </strong></div>
                                                        <hr className="my-3" />
                                                        <div className="row" > <span className="col-3" >KYC Type:</span> <strong className=" col"> {kycType} </strong>
                                                        </div>
                                                        <hr className="my-3" />
                                                        <div className="row" > <span className="col-3" >Document Type:</span> <strong className=" col"> {documentType} </strong></div>
                                                        <hr className="my-3" />
                                                        <div className="row">
                                                            <span className="mb-4 col-12" >Document Verification Images:</span>
                                                            <div className="col-6  mb-3">
                                                                <div className="doc_img">
                                                                    <div className="row mb-3">
                                                                        <div className="col">{documentType} <small> (Front) </small>
                                                                        </div>
                                                                    </div>
                                                                    {!frontImg ?
                                                                        <div className="ratio ratio-16x9">
                                                                            <img className="w-100" src="/assets/img/illustrations/profiles/nophoto.png" alt="" />
                                                                        </div> :
                                                                        <div className="ratio ratio-16x9">
                                                                            <img src={ApiConfig?.appUrl + frontImg} alt="" className="w-100 cursor_pointer" data-bs-toggle="modal" data-bs-target="#edit_modal" onClick={() => handleSubadminDetail(ApiConfig?.appUrl + frontImg)} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mb-3">
                                                                <div className="doc_img">
                                                                    <div className="row mb-3">
                                                                        <div className="col"> {documentType} <small> (Back) </small> </div>
                                                                    </div>
                                                                    {!backImg ?
                                                                        <div className="ratio ratio-16x9">
                                                                            <img className="w-100" src="/assets/img/illustrations/profiles/nophoto.png" alt="" />
                                                                        </div> :
                                                                        <div className="ratio ratio-16x9">
                                                                            <img src={ApiConfig?.appUrl + backImg} alt="" className="w-100 cursor_pointer" data-bs-toggle="modal" data-bs-target="#edit_modal" onClick={() => handleSubadminDetail(ApiConfig?.appUrl + backImg)} />
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className="col-6 mb-3">
                                                                <div className="doc_img">
                                                                    <div className="row mb-3">
                                                                        <div className="col"> Other Identity </div>
                                                                    </div>
                                                                    {!panImg ?
                                                                        <div className="ratio ratio-16x9">
                                                                            <img className="w-100" src="/assets/img/illustrations/profiles/nophoto.png" alt="" />
                                                                        </div> :
                                                                        <div className="ratio ratio-16x9">
                                                                            <img src={ApiConfig?.appUrl + panImg} alt="" className="w-100 cursor_pointer" data-bs-toggle="modal" data-bs-target="#edit_modal" onClick={() => handleSubadminDetail(ApiConfig?.appUrl + panImg)} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-6 mb-3">
                                                                <div className="doc_img">
                                                                    <div className="row mb-3">
                                                                        <div className="col"> Selfie </div>
                                                                    </div>
                                                                    {!selfie ?
                                                                        <div className="ratio ratio-16x9">
                                                                            <img className="w-100" src="/assets/img/illustrations/profiles/nophoto.png" alt="" />
                                                                        </div> :
                                                                        <div className="ratio ratio-16x9">
                                                                            <img src={ApiConfig?.appUrl + selfie} alt="" className="w-100 cursor_pointer" data-bs-toggle="modal" data-bs-target="#edit_modal" onClick={() => handleSubadminDetail(ApiConfig?.appUrl + selfie)} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* KYC Verification */}

                                            {/* Bank Account Details*/}
                                            <div className="tab-pane card" id="bankaccountPill" role="tabpanel" aria-labelledby="bankaccount-pill">
                                                <div className="card-header">Bank Account Details</div>

                                                <div className="profile_data py-5 px-4" >
                                                    <div className="row" >
                                                        <span className="col-3" >Bank Name: </span>
                                                        <strong className=" col text-success "> {/* <i className="fa fa-check-circle"></i> */} {bankName}  </strong>
                                                    </div>
                                                    <hr className="my-3" />
                                                    <div className="row" > <span className="col-3" >Bank Account Number:</span> <strong className=" col">{acNumber}</strong></div>
                                                    <hr className="my-3" />
                                                    <div className="row" > <span className="col-3" >Account Holder Name:</span> <strong className=" col"> {holderName} </strong></div>
                                                    <hr className="my-3" />
                                                    <div className="row" > <span className="col-3" >Branch Name:</span> <strong className=" col"> {branch} </strong></div>
                                                    <hr className="my-3" />
                                                    <div className="row" > <span className="col-3" >IFSC Code:</span> <strong className=" col"> {ifsc} </strong></div>
                                                </div>
                                            </div>

                                            {/* User Transitions*/}
                                            <div className="tab-pane card" id="TransitionsPill" role="tabpanel" aria-labelledby="Transitions-pill">
                                                <div className="card-header">User Trade Details
                                                    {
                                                        userTradeDetails.length === 0 ? "" :
                                                            <div className="dropdown">
                                                                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</button>
                                                                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                                                    <CSVLink data={userTradeDetails} className="dropdown-item">Export as CSV</CSVLink>
                                                                </div>
                                                            </div>
                                                    }

                                                </div>

                                                <div className="card-body" >
                                                    <form className="row">
                                                        <div className="col-12" >
                                                            <table className="" width="100%" >
                                                                <DataTableBase columns={columns} data={userTradeDetails} />

                                                            </table>
                                                        </div>
                                                    </form>
                                                </div>

                                            </div>

                                            {/* walletdetails*/}
                                            <div className="tab-pane card" id="walletdetails" role="tabpanel" aria-labelledby="walletdetails">
                                                <div className="card-header">User Wallet Details
                                                    {
                                                        walletdetails.length === 0 ? "" :
                                                            <div className="dropdown">
                                                                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</button>
                                                                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                                                    <CSVLink data={walletdetails} className="dropdown-item">Export as CSV</CSVLink>
                                                                </div>
                                                            </div>
                                                    }

                                                </div>
                                                <div className="card-body" >
                                                    <form className="row">
                                                        <div className="col-12" >
                                                            <table className="" width="100%" >
                                                                <DataTableBase columns={walletdetailscolumns} data={walletdetails} />
                                                            </table>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="tab-pane card" id="masterAdmin" role="tabpanel" aria-labelledby="bankaccount-pill">
                                                <div className="card-header">Users Referral</div>
                                                <div className="profile_data py-5 px-4" >
                                                    <div className="row" >
                                                        <label className="small mb-1" for="Maker">Users Referral Code</label>
                                                        <input type="text" className="form-control form-control-solid" id="Maker" name="firstName" value={`${deployedUrl}signup?reffcode=${referralCode}`} />
                                                    </div>
                                                    <hr className="my-3" />
                                                    <div className="row" >
                                                        <label className="small mb-1" for="taker">Users List</label>
                                                        <table>
                                                            <DataTableBase columns={UserReferralcolumns} data={referralList} />
                                                        </table>
                                                    </div>
                                                    <hr className="my-3" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </main>
                    {/* Trade Image Detail */}
                    <div className="modal image_modal" id="edit_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div className="modal-dialog  alert_modal modal-lg" role="document">
                            <div className="modal-content">
                                <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                                <div className="ratio ratio-16x9">
                                    <img src={showImage} className="w-100 cc_modal_img" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Trade Image Detail  */}


                    <div class="modal fade " id="editKyc" tabindex="-1" aria-labelledby="AddBankLaebl" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content ">

                                <div className="modal-header">
                                    <h5 className="modal-title" >
                                        Update User Kyc
                                    </h5>
                                    <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"> </button>
                                </div>
                                <div className="modal-body p-4">
                                    <form>
                                        <div className="row gx-3 mb-3">
                                            <div className="col-md-6">
                                                <label className="small mb-1" for="inputFirstName">First name </label>
                                                <input type="text" className="form-control  form-control-solid" id="inputFirstName" name="first_name" value={kycInputData?.first_name} onChange={handleKycInput} />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="small mb-1" for="inputLastNames">Middle name </label>
                                                <input className="form-control form-control-solid" id="inputLastNames" type="text" name="middle_name" value={kycInputData?.middle_name} onChange={handleKycInput} />
                                            </div>
                                        </div>
                                        <div className="row gx-3 mb-3">
                                            <div className="col-md-6">
                                                <label className="small mb-1" for="inputFirstName">Last name </label>
                                                <input type="text" className="form-control  form-control-solid" id="inputFirstName" name="last_name" value={kycInputData?.last_name} onChange={handleKycInput} />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="small mb-1" for="inputLastNames">DOB </label>
                                                <input className="form-control form-control-solid" id="inputLastNames" type="date" name="dob" value={kycInputData?.dob} onChange={handleKycInput} />
                                            </div>
                                        </div>
                                        <div className="row gx-3 mb-3">
                                            <div className="col-md-6">
                                                <label className="small mb-1" for="inputFirstName">Country</label>
                                                <input type="text" className="form-control  form-control-solid" id="inputFirstName" name="country" value={kycInputData?.country} onChange={handleKycInput} />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="small mb-1" for="inputLastNames">Address</label>
                                                <input className="form-control form-control-solid" id="inputLastNames" type="text" name="address" value={kycInputData?.address} onChange={handleKycInput} />
                                            </div>
                                        </div>
                                        <div className="row gx-3 mb-3">
                                            <div className="col-md-6">
                                                <label className="small mb-1" for="inputFirstName">State</label>
                                                <input type="text" className="form-control  form-control-solid" id="inputFirstName" name="state" value={kycInputData?.state} onChange={handleKycInput} />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="small mb-1" for="inputLastNames">City</label>
                                                <input className="form-control form-control-solid" id="inputLastNames" type="text" name="city" value={kycInputData?.city} onChange={handleKycInput} />
                                            </div>
                                        </div>
                                        <div className="row gx-3 mb-3">
                                            <div className="col-md-6">
                                                <label className="small mb-1" for="inputFirstName">Zip Code</label>
                                                <input type="text" className="form-control  form-control-solid" id="inputFirstName" name="zip_code" value={kycInputData?.zip_code} onChange={handleKycInput} />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="small mb-1" for="inputLastNames">KYC Type</label>
                                                <input className="form-control form-control-solid" id="inputLastNames" type="text" name="kyc_type" value={kycInputData?.kyc_type} onChange={handleKycInput} />
                                            </div>
                                        </div>
                                        <div className="row gx-3 mb-3">
                                            <div className="col-md-6">
                                                <label className="small mb-1" for="inputFirstName">Pancard No.</label>
                                                <input type="text" className="form-control  form-control-solid" id="inputFirstName" name="pancard_number" value={kycInputData?.pancard_number} onChange={handleKycInput} />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="small mb-1" for="inputLastNames">Confirm Pancard No.</label>
                                                <input className="form-control form-control-solid" id="inputLastNames" type="text" name="cpancard_number" value={kycInputData?.cpancard_number} onChange={handleKycInput} />
                                            </div>
                                        </div>
                                        <div className="row gx-3 mb-3">
                                            <div className="col-md-6">
                                                <label className="small mb-1" for="inputFirstName">Document Type</label>
                                                <select className="form-control  form-control-solid" name="document_type" value={kycInputData?.document_type} onChange={handleKycInput}>
                                                    <option value="Aadhaar" defaultValue>Aadhar card </option>
                                                    <option value="Driving License">Driving License</option>
                                                    <option value="passport">Passport</option>
                                                    <option value="Other">Other</option>
                                                </select>

                                            </div>
                                            <div className="col-md-6">
                                                <label className="small mb-1" for="inputLastNames">Document No.</label>
                                                <input className="form-control form-control-solid" id="inputLastNames" type="text" name="document_number" value={kycInputData?.document_number} onChange={handleKycInput} />
                                            </div>
                                        </div>
                                        <div className="row gx-3 mb-3">
                                            <div className="col-md-6">
                                                <label className="small mb-1" for="inputLocation"> Document Front Image</label>
                                                <input className="form-control form-control-solid" id="inputLocation" type="file" name="document_front_image" onChange={handleKycInputImages} />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="small mb-1" for="inputLocation"> Document Back Image</label>
                                                <input className="form-control form-control-solid" id="inputLocation" type="file" name="document_back_image" onChange={handleKycInputImages} />
                                            </div>
                                        </div>
                                        <div className="row gx-3 mb-3">
                                           
                                            <div className="col-md-6">
                                                <label className="small mb-1" for="inputLocation"> User Selfie</label>
                                                <input className="form-control form-control-solid" id="inputLocation" type="file" name="user_selfie" onChange={handleKycInputImages} />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="small mb-1" for="inputLocation"> Pancard Image</label>
                                                <input className="form-control form-control-solid" id="inputLocation" type="file" name="pancard_image" onChange={handleKycInputImages} />
                                            </div>
                                        </div>
                                        <div className="row gx-3 mb-3">
                                            <div className="col-md-6">
                                                <label className="small mb-1" for="inputEmailAddress">Email</label>
                                                <input className="form-control form-control-solid" id="inputEmailAddress" name="emailId" value={kycInputData?.emailId} onChange={handleKycInput} />
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <button className="btn btn-indigo  " type="button" onClick={submitKyc} > Submit Details </button>
                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            : <TradeList />

    )


}

export default TraderDetails;