import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, } from "../../../customComponent/CustomAlertMessage";
import moment from "moment";
import { CSVLink } from "react-csv";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";

const TradingCommision = () => {
  const [TradingCommission, setTradingCommission] = useState([]);
  const [allData, setallData] = useState([]);


  const columns = [
    { name: "Date", selector: row => moment(row?.createdAt).format("MMM Do YYYY"), },
    { name: "Name", wrap: true, selector: row => row.short_name, },
    { name: "Fee", selector: row => row.fee, },
    { name: "Fee Type", wrap: true, selector: row => row.fee_type, },
    { name: "From User", wrap: true, selector: row => row.from_user, },
    { name: "Percentage", selector: row => row.percentage, },
    { name: "Amount", selector: row => row.amount, },
  ];

  useEffect(() => {
    tradingCommission();
  }, []);

  const tradingCommission = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.tradingCommission().then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          setTradingCommission(result?.data?.reverse());
          setallData(result?.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage("Something Went Wrong");
      }
    });
  };
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const filterDate = () => {
    const filteredData = allData.filter((item) => {
      const createdAtDate = new Date(item.createdAt);
      return (
        (!fromDate || createdAtDate >= new Date(fromDate)) &&
        (!toDate || createdAtDate <= new Date(toDate))
      );
    });
    setTradingCommission(filteredData?.reverse())
  }
  const ResetfilterDate = () => {
    setFromDate('')
    setToDate('')
    setTradingCommission(allData)
  }

  function handleSearch(e) {
    const keysToSearch = ["short_name", "fee", "fee_type", "from_user", "percentage", "from_user", "amount"];
    const searchTerm = e.target.value?.toLowerCase();
    const matchingObjects = allData?.reverse().filter(obj => { return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm)) });
    setTradingCommission(matchingObjects);
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-dollar-sign"></i>
                    </div>
                    Trading Commission
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Main page content */}
        <div className="container-xl px-4 mt-n10">
          <div className="filter_bar">
            <form className="row">
              <div className="mb-3 col ">
                <input
                  type="date"
                  className="form-control form-control-solid"
                  data-provide="datepicker"
                  id="litepickerRangePlugin"
                  name="dateFrom"
                  value={fromDate}
                  onChange={(e) => { setFromDate(e.target.value); }}
                />
              </div>
              <div className="mb-3 col ">
                <input
                  type="date"
                  className="form-control form-control-solid"
                  data-provide="datepicker"
                  id="litepickerRangePlugin"
                  name="dateTo"
                  value={toDate}
                  onChange={(e) => { setToDate(e.target.value); }}
                />
              </div>
              <div className="mb-3 col ">
                <div className="row">
                  <div className="col">
                    <button
                      className="btn btn-indigo btn-block w-100"
                      type="button"
                      onClick={filterDate}
                    >
                      Search
                    </button>
                  </div>
                  <div className="col">
                    <button
                      className="btn btn-indigo btn-block w-100"
                      type="button"
                      onClick={ResetfilterDate}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="card mb-4">
            <div className="card-header">
              Trading Commission
              <div className="col-5">
                <input className="form-control form-control-solid" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={handleSearch} />
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-dark btn-sm dropdown-toggle"
                  id="dropdownFadeInUp"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Export{" "}
                </button>
                <div
                  className="dropdown-menu animated--fade-in-up"
                  aria-labelledby="dropdownFadeInUp"
                >
                  <CSVLink data={TradingCommission} className="dropdown-item">
                    Export as CSV
                  </CSVLink>
                </div>
              </div>
            </div>
            <div className="table-responsive" width="100%">
              <DataTableBase columns={columns} data={TradingCommission} />
            </div>


          </div>
        </div>
      </main>
    </div>
  );
};

export default TradingCommision;
