import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useNavigate, useParams } from 'react-router-dom'
import LoaderHelper from '../../../customComponent/Loading/LoaderHelper';
import AuthService from '../../../api/services/AuthService';
import { alertErrorMessage } from '../../../customComponent/CustomAlertMessage';
import DataTable from 'react-data-table-component';
import moment from 'moment';

const AffiliateUserDetails = () => {
    const { userId } = useParams();
    const navigate = useNavigate()
    const [userList, setUserList] = useState([]);
    const [allData, setAllData] = useState([]);

    const getUserReferredCode = async () => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.UserReferral(userId)
            if (result.success) {
                getRefferedUser(result?.data)
            }
        }
        catch (error) { alertErrorMessage(error); }
        finally { LoaderHelper.loaderStatus(false); }
    };

    const getRefferedUser = async (refferCode) => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.userReferralList(refferCode);
            if (result.success) {
                setUserList(result?.data)
                setAllData(result?.data)
            }
        }
        catch (error) { alertErrorMessage(error); }
        finally { LoaderHelper.loaderStatus(false); }
    };

    const columns = [
        { name: "Sr no.", selector: (row, index) => index + 1 },
        { name: "Registration Date", sort: true, selector: row => moment(row?.createdAt).format("Do MMMM YYYY") },
        { name: "User ID", selector: row => row._id, wrap: true },
        { name: "Name", sort: true, wrap: true, selector: row => row?.firstName + row?.lastName, },
        { name: "Email", sort: true, wrap: true, selector: row => row?.emailId, },
        { name: "Phone Number", sort: true, selector: row => row?.mobileNumber, },
        { name: "Invested Amount", sort: true, selector: row => row?.invested_amount, },

    ];

    function searchObjects(e) {
        const keysToSearch = ["firstName", "lastName", "emailId", "mobileNumber", "_id"];
        const userInput = e.target.value;
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = allData.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setUserList(matchingObjects);
    };

    const previousPage = () => navigate(-1)

    useEffect(() => {
        getUserReferredCode()
    }, []);
    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon cursor-pointer" onClick={previousPage}>
                                                <i className="fa fa-arrow-left" ></i>
                                            </div>
                                            Referred User List
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between">
                                Affiliate Details
                                <div className="col-5">
                                    <input className="form-control form-control-solid" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
                                </div>
                                <div className="dropdown">
                                    <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fa fa-download me-3"></i> Export
                                    </button>
                                    <div
                                        className="dropdown-menu animated--fade-in-up"
                                        aria-labelledby="dropdownFadeInUp"
                                    >
                                        <CSVLink data={userList} className="dropdown-item">
                                            Export as CSV
                                        </CSVLink>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive" width="100%">
                                    <DataTable columns={columns} data={userList} direction="auto" responsive subHeaderAlign="right" subHeaderWrap striped highlightOnHover fixedHeader />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default AffiliateUserDetails