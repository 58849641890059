import React, { useEffect, useState } from 'react'
import LoaderHelper from '../../../customComponent/Loading/LoaderHelper';
import AuthService from '../../../api/services/AuthService';
import { alertErrorMessage, alertSuccessMessage } from '../../../customComponent/CustomAlertMessage';

const AdminBankAccount = () => {
    const [bankName, setbankName] = useState('');
    const [name, setname] = useState('');
    const [accNumber, setaccNumber] = useState('');
    const [ifscCode, setifscCode] = useState('');
    const [branch, setbranch] = useState('');
    const [id, setid] = useState('');
    const [bankDetails, setbankDetails] = useState([]);

    useEffect(() => {
        getbankdetails()
    }, []);

    const EditBankDetails = async (bankName, name, accNumber, ifscCode, branch, id) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.editBankDetails(bankName, name, accNumber, ifscCode, branch, id).then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage('Bank Details Updated')
                getbankdetails()
                try {
                } catch (error) {
                    console.log(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };

    const getbankdetails = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getUserBankDetails().then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                setbankDetails(result?.data)
                try {
                } catch (error) {
                    console.log(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }


    const BankDataUpdate = (item) => {
        setbankName(item?.bank_name);
        setname(item?.holder_name);
        setaccNumber(item?.account_number);
        setifscCode(item?.ifsc);
        setbranch(item?.branch);
        setid(item?._id)
    }

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i class="fas fa-money-bill-wave"></i></div>
                                        Payment Details
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10 ">
                    <div className='row g-4 justify-content-center' >
                        {bankDetails ? bankDetails?.map((item) => {
                            return (
                                <div className='col-md-8' >
                                    <div className="card mb-4 h-100">
                                        <div class="card-body d-flex justify-content-center flex-column p-3 m-3 ">
                                            <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                <h5 class="mb-0">Bank Account Details</h5>
                                            </div>

                                            <hr class="mt-0" />
                                            <div class="w-100">
                                                <div class="d-flex mb-2 align-items-center justify-content-between">
                                                    <strong>Bank Name</strong>
                                                    <span>{item?.bank_name}</span></div>
                                            </div><div class="w-100">
                                                <div class="d-flex mb-2 align-items-center justify-content-between">
                                                    <strong>Account Number</strong>
                                                    <span>{item?.account_number}</span>
                                                </div>
                                            </div>
                                            <div class="w-100">
                                                <div class="d-flex mb-2 align-items-center justify-content-between">
                                                    <strong>Account Holder Name</strong>
                                                    <span>{item?.holder_name}</span>
                                                </div>
                                            </div>
                                            <div class="w-100">
                                                <div class="d-flex mb-2 align-items-center justify-content-between">
                                                    <strong>Branch</strong>
                                                    <span>{item?.branch}</span>
                                                </div>
                                            </div>
                                            <div class="w-100">
                                                <div class="d-flex mb-2 align-items-center justify-content-between">
                                                    <strong>IFSC</strong>
                                                    <span>{item?.ifsc} </span>
                                                </div>
                                            </div>
                                            <form>
                                                <div class="row mt-2"><div class="col"><button class="btn btn-success btn-block w-100 mt-2" data-bs-toggle="modal" data-bs-target="#bank_modal" type="button" onClick={() => BankDataUpdate(item)}>Edit</button></div></div></form></div>

                                    </div>
                                </div>
                            )
                        }) : ''}
                    </div>
                </div>
                {/* ******Bank Modal******** */}
                <div class="modal" id="bank_modal" tabindex="-1" aria-hidden="true" ><div class="modal-dialog  alert_modal" role="document"><div class="modal-content"><div class="modal-header"><h5 class="modal-title" id="exampleModalCenterTitle">Edit Bank Account</h5><button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div><div class="modal-body">
                    <form >
                        <div className="mb-3   ">
                            <label className="text-gray-600 small" for="emailExample">Bank Name</label>
                            <input className="form-control form-control-solid" type="text" onChange={(e) => { setbankName(e.target.value) }} value={bankName} aria-label="Email Address" aria-describedby="emailExample" />
                        </div>
                        <div className="mb-3   ">
                            <label className="text-gray-600 small" for="emailExample">Account Holder Name</label>
                            <input className="form-control form-control-solid" type="text" aria-label="Email Address" aria-describedby="emailExample" onChange={(e) => { setname(e.target.value) }} value={name} />
                        </div>
                        <div className="mb-3   ">
                            <label className="text-gray-600 small" for="emailExample">Account Number</label>
                            <input className="form-control form-control-solid" type="number" aria-label="Email Address" aria-describedby="emailExample" onChange={(e) => { setaccNumber(e.target.value) }} value={accNumber} onWheel={(e) => e.target.blur()} />
                        </div>
                        <div className="mb-3   ">
                            <label className="text-gray-600 small" for="emailExample">Branch</label>
                            <input className="form-control form-control-solid" type="text" aria-label="Email Address" aria-describedby="emailExample" onChange={(e) => { setbranch(e.target.value) }} value={branch} />
                        </div>
                        <div className="mb-3  ">
                            <label className="text-gray-600 small" for="emailExample">IFSC Code</label>
                            <input className="form-control form-control-solid" type="text" aria-label="Email Address" aria-describedby="emailExample" onChange={(e) => { setifscCode(e.target.value) }} value={ifscCode} />
                        </div>
                        <button className="btn btn-large btn-primary bn-block w-100 mt-3 btn_admin" data-bs-dismiss="modal" aria-label="Close" type="button" onClick={() => { EditBankDetails(bankName, name, accNumber, ifscCode, branch, id) }}> Edit Bank</button>

                    </form>

                </div></div></div></div>




            </main>
        </div>
    )
}


export default AdminBankAccount
